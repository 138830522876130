$(document).ready(initPage);

function initPage() {
  function checkScroll() {
    if ($(window).scrollTop() > 0) {
      $('body').addClass('is-scrolled');
    } else {
      $('body').removeClass('is-scrolled');
    }
  }
  checkScroll();
  $(window).scroll(function () {
    checkScroll();
  });
  $('.toggler').click(function () {
    $('body').toggleClass('is-menu');
  });

  /* hero slider */
  let heroSwiper;

  function initializeHeroSlider() {
    if ($(window).width() >= 1024) {
      if (!heroSwiper) {
        heroSwiper = new Swiper('.swiper--hero', {
          slidesPerView: 1,
          autoplay: {
            delay: 5000,
          },
          speed: 500,
          effect: 'fade',
          fadeEffect: {
            crossFade: true,
          },
          on: {
            init: function () {
              const swiper = this;

              document
                .querySelectorAll('.service-item')
                .forEach(function (button) {
                  button.classList.remove('active');
                });
              document
                .querySelector(
                  `.service-item[data-slide="${swiper.realIndex}"]`
                )
                .classList.add('is-active');

              document
                .querySelectorAll('.service-item')
                .forEach(function (button) {
                  button.addEventListener('mouseover', function () {
                    const slideIndex = this.getAttribute('data-slide');
                    swiper.slideToLoop(slideIndex);
                  });
                });
            },
            slideChange: function () {
              const swiper = this;

              document
                .querySelectorAll('.service-item')
                .forEach(function (button) {
                  button.classList.remove('is-active');
                });
              document
                .querySelector(
                  `.service-item[data-slide="${swiper.realIndex}"]`
                )
                .classList.add('is-active');
            },
          },
        });
      }
    } else {
      if (heroSwiper) {
        heroSwiper.destroy(true, true);
        heroSwiper = null;
      }
    }
  }

  initializeHeroSlider();

  $(window).resize(function () {
    initializeHeroSlider();
  });
  /* end hero slider */

  document.querySelectorAll('.service-circle').forEach(function (button) {
    button.addEventListener('click', function () {
      var slideIndex = this.getAttribute('data-slide');
      swiper.slideTo(slideIndex);
    });
  });

  const newsSwiper = new Swiper('.swiper--news', {
    slidesPerView: 3,
    spaceBetween: 34,
    loop: true,
    navigation: {
      prevEl: '.section-news .swiper-prev',
      nextEl: '.section-news .swiper-next',
    },
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 3,
        spaceBetween: 34,
      },
    },
  });
  const partnersSwiper = new Swiper('.swiper--partners', {
    slidesPerView: 7,
    spaceBetween: 40,
    loop: true,
    speed: 6000,
    centeredSlides: true,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    loopedSlides: 9,
    breakpoints: {
      320: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      481: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      576: {
        slidesPerView: 4,
        spaceBetween: 30,
      },
      768: {
        slidesPerView: 5,
        spaceBetween: 30,
      },
      1200: {
        slidesPerView: 6,
        spaceBetween: 40,
      },
      1400: {
        slidesPerView: 7,
        spaceBetween: 40,
      },
      1600: {
        slidesPerView: 8,
        spaceBetween: 40,
      },
    },
  });

  const serviceSwiper = new Swiper('.swiper--service', {
    slidesPerView: 1,
    loop: true,
    navigation: {
      prevEl: '.section-service .swiper-prev',
      nextEl: '.section-service .swiper-next',
    },
  });

  $('.service-other h3').click(function () {
    $('.service-other').toggleClass('is-active');
  });

  // const postThumbsSwiper = new Swiper('.swiper--post-thumbs', {
  //   direction: 'vertical',
  //   loop: true,
  //   spaceBetween: 45,
  //   slidesPerView: 3,
  //   // freeMode: true,
  //   watchSlidesProgress: true,
  //   watchOverflow: true,
  //   slideToClickedSlide: true,
  //   loopedSlides: 4,
  // });

  // const postSwiper = new Swiper('.swiper--post', {
  //   slidesPerView: 1,
  //   spaceBetween: 45,
  //   loop: true,
  //   loopedSlides: 4,
  //   effect: 'fade',
  //   fadeEffect: {
  //     crossFade: true,
  //   },
  //   navigation: {
  //     prevEl: '.section-post .swiper-prev',
  //     nextEl: '.section-post .swiper-next',
  //   },
  //   thumbs: {
  //     swiper: postThumbsSwiper,
  //   },
  //   on: {
  //     slideChange: function () {
  //       // Ensure that the thumbnail swiper updates correctly
  //       console.log('slide change');
  //       let realIndex = this.realIndex;
  //       postThumbsSwiper.slideToLoop(realIndex); // Keep thumbs in sync during drag
  //     },
  //   },
  // });

  function updateThumbnails(realIndex) {
    const thumbContainers = document.querySelectorAll('.thumb-container');
    const swiperWrapper = document.querySelector(
      '.post-photos .swiper-wrapper'
    );
    const slides = swiperWrapper.children;
    console.log('1');

    // Calculate the index of the next slide after the current one
    let nextIndex = (realIndex + 1) % slides.length;

    // If the next slide is the same as the current one, find the next unique slide
    if (nextIndex === realIndex) {
      nextIndex = (nextIndex + 1) % slides.length;
    }
    console.log('2');

    // Set the image source for the first thumb container to the next slide's image
    thumbContainers[0].querySelector('img').src =
      slides[nextIndex].querySelector('img').src;

    // Calculate the index of the slide after the next one
    let secondNextIndex = (nextIndex + 1) % slides.length;

    // Set the image source for the second thumb container to the slide after the next one's image
    thumbContainers[1].querySelector('img').src =
      slides[secondNextIndex].querySelector('img').src;
    console.log('3');
  }

  let isUpdatingThumbnails = false;

  const postSwiper = new Swiper('.swiper--post', {
    slidesPerView: 1,
    spaceBetween: 45,
    loop: true,
    effect: 'fade',
    fadeEffect: {
      crossFade: true,
    },
    navigation: {
      prevEl: '.post-photos .swiper-prev',
      nextEl: '.post-photos .swiper-next',
    },
  });
}
